import React, { useEffect, useState, useRef } from 'react';
import { styled, keyframes, useTheme } from '@mui/system';

const moveCloud = keyframes`
  to {
    transform: translateX(100%);
  }
`;

const CloudImg = styled('img')({
  position: 'absolute',
  animation: `${moveCloud} linear infinite`,
});

const CloudAnimation = () => {
  const theme = useTheme();
  const [clouds, setClouds] = useState([]);
  const isScrolling = useRef(false);
  const cloudRefs = useRef([]);
  const observer = useRef(null); // Add this line

  const generateRandomNumber = (min, max) => Math.random() * (max - min) + min;

  const createCloud = () => {
    return {
      id: Date.now(),
      ref: React.createRef(),
      left: `${generateRandomNumber(0, window.innerWidth)}px`,
      top: `${generateRandomNumber(0, window.innerHeight)}px`,
      zIndex: Math.floor(generateRandomNumber(0, 3)), // 0, 1, or 2
      width: `${generateRandomNumber(50, 400)}px`,
      opacity: generateRandomNumber(0.2, 1),
      speedMultiplier: generateRandomNumber(5, 20),
      animationDuration: `${generateRandomNumber(20, 40)}s`,
      animationDelay: `${generateRandomNumber(0, 5)}s`,
    };
  };

  const animationEndHandler = (id) => {
    setClouds((prevClouds) => prevClouds.filter((cloud) => cloud.id !== id));
    setClouds((prevClouds) => [...prevClouds, createCloud()]);
  };

  useEffect(() => {
    const maxClouds = 6;
    cloudRefs.current = Array.from({ length: maxClouds }, () => React.createRef());
    observer.current = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    });

    const intervalId = setInterval(() => {
      if (!isScrolling.current) {
        setClouds((prevClouds) => {
          const updatedClouds = prevClouds.map((cloud, index) => {
            const newLeft = `calc(${cloud.left} + ${theme.spacing(0.001 * cloud.speedMultiplier)})`;
            return {
              ...cloud,
              left: newLeft,
              ...(parseInt(newLeft) + parseInt(cloud.width) < 0 && { left: `${window.innerWidth}px` }),
            };
          });

          if (prevClouds.length < maxClouds) {
            updatedClouds.push(createCloud());
          }

          return updatedClouds.slice(0, maxClouds);
        });
      }
    }, 20);

    const handleScroll = () => {
      isScrolling.current = true;
      clearInterval(intervalId);

      setTimeout(() => {
        isScrolling.current = false;
      }, 200);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener('scroll', handleScroll);
      observer.current.disconnect(); // Disconnect the observer on unmount
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme.spacing]);

  useEffect(() => {
    cloudRefs.current.forEach((cloudRef) => {
      if (cloudRef.current) {
        observer.current.observe(cloudRef.current);
      }
    });
  
    return () => {
      cloudRefs.current.forEach((cloudRef) => {
        if (cloudRef.current) {
          observer.current.unobserve(cloudRef.current);
        }
      });
    };
  }, [observer, cloudRefs]);

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) {
        const cloudId = parseInt(entry.target.getAttribute('data-id'));
        setClouds((prevClouds) => prevClouds.filter((cloud) => cloud.id !== cloudId));
      }
    });
  };
  return (
    <div style={{ position: 'relative', width: '100vw', height: '90vh', overflow: 'hidden' }}>
      {clouds.map((cloud, index) => (
        <CloudImg
          key={cloud.id}
          src="cloud 1.png"
          alt="Cloud"
          ref={cloudRefs.current[index]}
          data-id={cloud.id}
          style={{
            top: cloud.top,
            left: cloud.left,
            width: cloud.width,
            zIndex: cloud.zIndex,
            animationDuration: cloud.animationDuration,
            animationDelay: cloud.animationDelay,
            opacity: cloud.opacity,
          }}
          onAnimationEnd={() => animationEndHandler(cloud.id)}
        />
      ))}
    </div>
  );
};

export default CloudAnimation;

