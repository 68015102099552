import React from "react";
import {
  Typography,
  Box,
} from "@mui/material";

import EmployeeCard from "../EmployeeCard";

const styles = {
    header: {
      fontWeight: 'bold',
      fontSize: '60px'
    },
  };

  const EmployeeSection = ({ titleText, subtitleText, cardData }) => {
    return (
      <div style={{ backgroundColor: '#FEFAF0', minHeight: '80vh' }}>
        <div style={{
          alignItems: 'center',
          textAlign: 'center',
        }}>
          <div>
            <Typography variant="h5" style={{ fontWeight: 'lighter', color: '#FF4848', letterSpacing: 3, paddingBottom: '2vh', paddingTop: '5vh' }}>
              {titleText}
            </Typography>
            <Typography variant="h1" style={{ ...styles.header, paddingBottom: '10vh' }}>
              {subtitleText}
            </Typography>
          </div>
        </div>
        {cardData ? <ThreeCardsRow cardData={cardData} /> : null}
      </div>
    );
  }  
  
  export default EmployeeSection;

  const ThreeCardsRow = ({ cardData }) => {
    const cardsInRows = [];
    const cardsPerRow = 3;
  
    for (let i = 0; i < cardData.length; i += cardsPerRow) {
      const rowCards = cardData.slice(i, i + cardsPerRow);
      const row = (
        <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
          justifyContent: 'space-between',
          padding: { xs: '0vh 5vh 10vh 5vh', md: '0vh 10vh 10vh 10vh' },
        }}
      >
          {rowCards.map((card, index) => (
            <EmployeeCard
              key={index}
              iconImage={card.iconImage}
              header={card.header}
              subheader={card.subheader}
              body={card.body}
            />
          ))}
        </Box>
      );
      cardsInRows.push(row);
    }
  
    return <>{cardsInRows}</>;
  };
  