import React from "react";
import Button from "@mui/material/Button";
import {
  Container,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import GridWithCheckIcons from "../GridWithCheckIcons";
import CheckList from "../CheckList";

export default function PricingSection({ transparencyText, transparencyHeader, price, elitePlanText1, elitePlanText2, indexedTextEntries, ListEntries}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Adjust breakpoint as needed

  if (!isMobile) {
  return (
    <div style={{ backgroundColor: '#3975B1', display: 'flex', minHeight: '80vh' }}>

      <div style={{ flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: '5vw', paddingRight: '30px' }}>
        <CheckList listEntries={ListEntries} />
      </div>

      <div style={{ flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: '5vw', paddingRight: '30px' }}>
        <Typography variant="h1" fontWeight="800" color="#FDD06B" gutterBottom style={{ fontSize: '60px', paddingTop: '15vh' }}>
          {elitePlanText1}
        </Typography>
        <Divider style={{ borderColor: 'white' }} />
        <Typography variant="h1" fontWeight="800" color="white" gutterBottom style={{ fontSize: '60px', paddingTop: '15px' }}>
          {price}
        </Typography>
        <Typography variant="body1" fontWeight="500" paddingBottom="16px" color="white" style={{ width: '80%', paddingTop: '15px' }}>
          {elitePlanText2}
        </Typography>
        <Divider style={{ borderColor: 'white' }} />
        <div style={{ flex: '1', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', paddingRight: '30px', paddingTop: '50px' }}>
          <Button
            color='inherit'
            variant='outlined'
            style={{
              backgroundColor: 'white',
              color: 'black',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              height: '50px',
              width: '30%',
              borderRadius: '8px',
              borderWidth: '1px',
            }}
            onClick={() => {
              // Handle sign-up button click if needed
            }}
          >
            Sign Up
          </Button>
        </div>
      </div>
    </div>
  );
  }
  return (
    <div style={{ backgroundColor: '#3975B1', display: 'flex', minHeight: '80vh', flexDirection: 'column-reverse' }}>
      <div style={{ flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: '5vw', paddingRight: '5vw' }}>
        <Typography variant="h1" fontWeight="bold" color="white" gutterBottom style={{ fontSize: '4vw', paddingTop: '5vh' }}>
          {elitePlanText1}
        </Typography>
        <Divider style={{ borderColor: 'white' }} />
        <Typography variant="h1" fontWeight="bold" color="white" gutterBottom style={{ fontSize: '4vw', paddingTop: '1vh' }}>
          {price}
        </Typography>
        <Typography variant="body1" paddingBottom="16px" color="white" style={{ width: '80%', paddingTop: '1vh', fontSize: '1.5vw' }}>
          {elitePlanText2}
        </Typography>
        <Divider style={{ borderColor: 'white' }} />
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', paddingTop: '2vh' }}>
          <Container style={{ padding: '0 5vw 0 0' }}>
            <GridWithCheckIcons indexedTextEntries={indexedTextEntries} />
          </Container>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', paddingTop: '2vh', paddingBottom: '5vh' }}>
          <Button
            color='inherit'
            variant='outlined'
            style={{
              backgroundColor: 'white',
              color: 'black',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              height: '5vh',
              width: '30%',
              borderRadius: '8px',
              borderWidth: '1px',
              fontSize: '1.5vw',
            }}
            onClick={() => {
              // Handle sign-up button click if needed
            }}
          >
            Sign Up
          </Button>
        </div>
      </div>
      <div style={{ flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: '5vw', paddingRight: '5vw' }}>
        <Typography variant="h5" style={{ fontWeight: 'lighter', color: 'white', letterSpacing: 3, paddingTop: '10vh', fontSize: '2vw' }}>
          {transparencyText}
        </Typography>
        <Typography variant="h1" fontWeight="bold" color="white" gutterBottom style={{ fontSize: '4vw', paddingTop: '1vh' }}>
          {transparencyHeader}
        </Typography>
        <Typography variant="body1" paddingBottom="16px" color="white" style={{ width: '80%', paddingTop: '1vh', fontSize: '1.5vw' }}>
          {transparencyText}
        </Typography>
        <CheckList listEntries={ListEntries} />
      </div>
    </div>
  );
}