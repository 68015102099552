import React from "react";
import {
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AccordionComponent from "../AccordionComponent";


const styles = {

  root: {
    width: "100%",
    display: 'flex',
    alignItems: 'center',
    background: '#FEFAF0',
  },
  image: {
    width: '30vw',
    height: 'auto',
    marginRight: '16px', // Assuming theme.spacing(2) corresponds to 16px
  },
  content: {
    flex: 1,
  },
  header: {
    fontWeight: '1000',
    paddingTop: '5vh',
    maxWidth: '90%',
    paddingLeft: '5vw',
    fontSize: '60px', // Default font size for larger screens (lg)
      '@media (max-width: 1200px)': {
        fontSize: '50px', // Adjust for medium screens (md)
      },
      '@media (max-width: 992px)': {
        fontSize: '40px', // Adjust for small screens (sm)
      },
      '@media (max-width: 768px)': {
        fontSize: '30px', // Adjust for extra-small screens (xs)
      },
  },
  subheader: {
    marginTop: '16px', // Assuming theme.spacing(2) corresponds to 16px
    marginBottom: '32px', // Assuming theme.spacing(4) corresponds to 32px
    fontSize: '18px', // Default font size for larger screens (lg)
    paddingLeft: '5vw',
    maxWidth: '90%',
    minWidth: '300px',
      '@media (max-width: 1200px)': {
        fontSize: '16px', // Adjust for medium screens (md)
      },
      '@media (max-width: 992px)': {
        fontSize: '14px', // Adjust for small screens (sm)
      },
      '@media (max-width: 768px)': {
        fontSize: '12px', // Adjust for extra-small screens (xs)
      },
  },
};

const QuestionSection = ({mainTitle, subTitle, accordionItems}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <div style={{ backgroundColor: 'white', display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
        <Typography variant="h1" sx={{ ...styles.header }}>
          {mainTitle}
        </Typography>
        <Typography variant="body1" sx={{ ...styles.subheader }}>
          {subTitle}
        </Typography>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
        <AccordionComponent accordionItems={accordionItems} />
      </div>
    </div>
  );
}

export default QuestionSection;