import React from "react";
import Button from "@mui/material/Button";
import {
  Grid,
  Typography,
} from "@mui/material";

const styles = {

    root: {
      display: 'flex',
      alignItems: 'center',
      background: '#FEFAF0',
    },
    image: {
      width: '30vw',
      paddingLeft: '5vw',
      paddingRight: '5vw',
      height: 'auto',
      marginRight: '16px', // Assuming theme.spacing(2) corresponds to 16px
    },
    content: {
      flex: 1,
    },
    header: {
      fontWeight: '1000',
      fontSize: '60px', // Default font size for larger screens (lg)
        '@media (max-width: 1200px)': {
          fontSize: '50px', // Adjust for medium screens (md)
        },
        '@media (max-width: 992px)': {
          fontSize: '40px', // Adjust for small screens (sm)
        },
        '@media (max-width: 768px)': {
          fontSize: '30px', // Adjust for extra-small screens (xs)
        },
    },
    header2: {
      fontWeight: '800',
      fontSize: '40px', // Default font size for larger screens (lg)
        '@media (max-width: 1200px)': {
          fontSize: '30px', // Adjust for medium screens (md)
        },
        '@media (max-width: 992px)': {
          fontSize: '25px', // Adjust for small screens (sm)
        },
        '@media (max-width: 768px)': {
          maxWidth: '200px',
          fontSize: '20px', // Adjust for extra-small screens (xs)
        },
    },
    subheader: {
      marginTop: '16px', // Assuming theme.spacing(2) corresponds to 16px
      marginBottom: '32px', // Assuming theme.spacing(4) corresponds to 32px
      fontSize: '18px', // Default font size for larger screens (lg)
      minWidth: '300px',
        '@media (max-width: 1200px)': {
          fontSize: '16px', // Adjust for medium screens (md)
        },
        '@media (max-width: 992px)': {
          fontSize: '14px', // Adjust for small screens (sm)
        },
        '@media (max-width: 768px)': {
          fontSize: '12px', // Adjust for extra-small screens (xs)
        },
    },
    subheader2: {
      marginTop: '16px', // Assuming theme.spacing(2) corresponds to 16px
      marginBottom: '32px',
      width: '70%',
      fontSize: '18px', // Default font size for larger screens (lg)
        '@media (max-width: 1200px)': {
          fontSize: '16px', // Adjust for medium screens (md)
        },
        '@media (max-width: 992px)': {
          fontSize: '14px', // Adjust for small screens (sm)
        },
        '@media (max-width: 768px)': {
          fontSize: '12px', // Adjust for extra-small screens (xs)
          maxWidth: '200px'
        },
    },
    listItem: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '8px', // Assuming theme.spacing(1) corresponds to 8px
    },
    listButtons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    checkIcon: {
      marginRight: '8px', // Assuming theme.spacing(1) corresponds to 8px
      color: '#FF4848', // Replace with your desired color
    },
    reviewRoot: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
    reviewContainer: {
      shadows: 'none',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
      padding: '50px',
    },
    scrollableContainer: {
      overflowX: 'auto',
      whiteSpace: 'nowrap', 
    },
    centeredImage: {
      position: "absolute",
      width: "125%",
      height: "95vh",
      objectFit: "cover",
      mixBlendMode: "multiply",
      zIndex: -2,
      left: "-10vw",
    },
    scrollingBanner: {
      transform: 'translateX(-15px) rotate(5deg)', // Apply the rotation
      width: "110%",
      paddingTop: "50px",
      paddingBottom: "15vh"
    },
    section: {
      marginTop: 50, // space between sections
      marginBottom: 50,
      paddingTop: 30, // space within each section
      paddingBottom: 30,
    },
    last: {
      marginTop: 80, // space between sections
      paddingTop: 30, // space within each section
    },
    contentOverlay: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    scrollableContent: {
      height: "100vh",
      overflowY: "auto",
    },
  };

  const ProfitabilitySection = ({backgroundColor, bodyColor, mainLine, mainLineColor, headerText, subheaderText, profitIncreaseText, controlMenuText, subText1, subText2, buttonText, headerColor }) => {
    return (
      <div>
        <div style={{
          ...styles.root,
          background: backgroundColor,
          overflow: 'hidden',
          minHeight: '60vh',
          padding: '0 5vw 0 5vw',
        }}>
          <div sx={{ ...styles.content, paddingLeft: '5vw' }}>
            <Typography variant="h5" style={{ fontWeight: 'lighter', color: mainLineColor, letterSpacing: 3, paddingBottom: '2vh' }}>
              {mainLine}
            </Typography>
            <Typography variant="h1" sx={{ ...styles.header, fontSize: '50px', color: headerColor }}>
              {headerText}
            </Typography>
            <Typography variant="body1" sx={{...styles.subheader, color: bodyColor, paddingRight: '10vh'}}>
              {subheaderText}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>
                    <Typography variant="h1" sx={{...styles.header2, color: mainLineColor, fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                      {profitIncreaseText}
                    </Typography>
                    <Typography variant="body1" sx={{...styles.subheader2, color: bodyColor }}>
                      {subText1}
                    </Typography>
                    <Button
                      color='inherit'
                      variant='outlined'
                      style={{
                        backgroundColor: mainLineColor,
                        color: backgroundColor,
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        height: '40px',
                        minWidth: '8vw',
                        borderRadius: '8px',
                        borderWidth: '1px',
                        marginTop: '50px',
                        padding: '0 10px 0 10px'
                      }}
                      onClick={() => {
                        // Handle sign-up button click if needed
                      }}
                    >
                      {buttonText}
                    </Button>
                  </div>
                </div>
              </Grid>
  
              <Grid item xs={6}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>
                    <Typography variant="h1" sx={{...styles.header2, color: mainLineColor, fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                      {controlMenuText}
                    </Typography>
                    <Typography variant="body1" sx={{...styles.subheader2, color: bodyColor }}>
                      {subText2}
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
  
  export default ProfitabilitySection;