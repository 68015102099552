import React from 'react';
import { Grid, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const CheckList = ({ listEntries }) => (
    <Grid container spacing={2}>
      {listEntries.map((entry, index) => (
        <Grid item key={index} xs={12}>
          <Typography variant="body1" style={{ fontWeight: '800', color: '#FDD06B', display: 'flex', alignItems: 'center' }}>
            <CheckCircleIcon style={{ paddingRight: '10px', color: '#FDD06B'}} />
            {entry.header}
          </Typography>
          <Typography variant="body1" style={{ fontWeight: '100', display: 'flex', alignItems: 'center', color: 'white', paddingBottom: '2vh' }}>
            {entry.content}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
  
export default CheckList;