import React from "react";
import { ParallaxProvider} from "react-scroll-parallax";
import Navbar from "../components/Navbar";
import DollarsSection from "../components/page-sections/DollarsComponent";
import ProfitsSection from "../components/page-sections/ProfitsSection";
import EmpowerSection from "../components/page-sections/EmpowerSection";
import QuestionSection from "../components/page-sections/QuestionSection";
import CompetitiveSection from "../components/page-sections/CompetitiveSection";


export default function RentingPage() {
  const accordionItems = [
    { title: 'How can I rent tools, equipment, or services on Tooli?', content: "Renting on Tooli is easy! Simply navigate to the 'Rent' section on the platform and explore the comprehensive marketplace. Use the precision search algorithms to find the specific tools or services you need. Once you find what you're looking for, follow the straightforward process to make a rental request." },
    { title: 'What is Tooli’s pricing structure for renters?', content: "Tooli offers transparent pricing for renters. You'll find competitive and affordable rates for the tools or services you choose to rent. The pricing is clear, with no hidden costs. Learn more about Tooli's pricing in the Pricing Section." },
    { title: 'How does Tooli ensure secure transactions for renters and listers?', content: 'Tooli prioritizes secure transactions. The platform incorporates a secure payment system using Stripe, ensuring that your payments are processed safely. Renters can have peace of mind knowing that transactions are protected, providing a smooth and secure rental experience for both parties.' },
    { title: 'Can I find specialized tools or services on Tooli?', content: 'Absolutely! Tooli caters to a diverse range of tools and services, including specialized equipment. Explore detailed listings to find unique offerings that match your specific needs. Renters can discover and rent tools or services tailored to their projects or requirements.' },
    { title: 'How can I ensure I find the right tool for my project?', content: 'To ensure you find the right tool for your project, use Tooli’s precision search algorithms effectively. Specify your requirements, explore detailed tooling uses in each listing, and utilize filters for criteria such as tool type, location, and availability. For more tips, check our guide on optimizing your rental experience in the Help Center.' },
  ]

  
  return (
    <ParallaxProvider>
      <Navbar />
      <div style={{ height: '10vh' }} />
      <ProfitsSection
        textColor='#123E64'
        titleText="Tooli Rentals"
        subtitleText="Explore a World of Tools and Services."
      />
      <CompetitiveSection
        backgroundColor="#FEFAF0"
        title="Discover a New Way to Rent with Tooli."
        subtitle="Tooli opens the door to a revolutionary renting experience. Explore a diverse range of tools, equipment, and services from local listers. Join our platform to enjoy competitive pricing, transparency, and the Golden Seal delivery method for untampered items."
        items={[
          { src: "coins-stack-2.svg", title: "Smart Pricing Advantage", description: "Unlock unparalleled value with Tooli's smart pricing model. Benefit from cost advantages of up to 30% over traditional rental services. The price you see during your search is what you'll pay, ensuring consistency and transparency throughout the renting process." },
          { src: "select-circle-area-1.svg", title: "Clear and Transparent Transactions", description: "In a world where pricing can be ambiguous, Tooli stands out for its commitment to clear and transparent transactions. The price displayed while browsing is the exact amount you'll pay upon making a rental, reflecting our integrity and respect for our renters." },
          { src: "happy-face-1.svg", title: "User-Friendly Platform", description: "Experience the ease of Tooli's user-friendly platform. Renting tools and equipment has never been simpler. Our intuitive design ensures a seamless navigation experience, making it easy for you to find and rent the items you need hassle-free." },
        ]}
        
        buttonText="Download"
        image="/DIYImage.png"
        learnMoreText="Learn More"
      />
      <DollarsSection
        backgroundColor="#FEFEFE"
        textColor='#123E64'
        fontWeight='1000'
        image="/marketingImage.png"
        title="Explore Endless Possibilities with Tooli Rentals"
        subtitle="Browse, rent, and experience the convenience of Tooli's comprehensive marketplace. Find the tools, equipment, or services you need for your projects. Join Tooli today and embark on a hassle-free renting journey."
        buttonText="Get Started"
      />
      <EmpowerSection
        subtitleColor="white"
        titleColor="#FDD06B"
        imageWidth="50vh"
        image="/MoneyImage.png"
        backgroundColor="#123E64"
        title="Waste Less, Do More"
        subtitle="Renting on Tooli isn't just about acquiring tools; it's about reducing tool waste while unlocking unlimited possibilities. Enjoy a platform that values your renting experience, providing access to a wide range of options, user-friendly support, and transparent rental processes."
        buttonText="Learn More"
      />
      <QuestionSection
        accordionItems={accordionItems}
        mainTitle="Questions"
        subTitle="Find answers to common questions and concerns about renting tools, equipment, or services on Tooli."
      />
    </ParallaxProvider>
  );
}
