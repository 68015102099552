import React from "react";
import Button from "@mui/material/Button";
import {
  List,
  ListItem,
  Typography,
  Box,
} from "@mui/material";

const styles = {

    root: {
      width: "100%",
      display: 'flex',
      alignItems: 'center',
      background: '#3975B1',
    },
    image: {
      width: '35vw',
      paddingLeft: '8vw',
      height: 'auto',
      marginRight: '30px', // Assuming theme.spacing(2) corresponds to 16px
      borderRadius: '16px',
    },
    content: {
      flex: 1,
    },
    header: {
      fontWeight: '900',
      color: '#FEFEFE',
      fontSize: '60px', // Default font size for larger screens (lg)
        '@media (max-width: 1200px)': {
          fontSize: '50px', // Adjust for medium screens (md)
        },
        '@media (max-width: 992px)': {
          fontSize: '40px', // Adjust for small screens (sm)
        },
        '@media (max-width: 768px)': {
          fontSize: '30px', // Adjust for extra-small screens (xs)
        },
    },
    subheader: {
      marginTop: '16px', // Assuming theme.spacing(2) corresponds to 16px
      color: '#FEFEFE',
      marginBottom: '32px', // Assuming theme.spacing(4) corresponds to 32px
      fontSize: '18px', // Default font size for larger screens (lg)
        '@media (max-width: 1200px)': {
          fontSize: '16px', // Adjust for medium screens (md)
        },
        '@media (max-width: 992px)': {
          fontSize: '14px', // Adjust for small screens (sm)
        },
        '@media (max-width: 768px)': {
          fontSize: '12px', // Adjust for extra-small screens (xs)
        },
    },
    listItem: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '8px', // Assuming theme.spacing(1) corresponds to 8px
    },
    listButtons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    checkIcon: {
      marginRight: '8px', // Assuming theme.spacing(1) corresponds to 8px
      color: '#FF4848', // Replace with your desired color
    },
  };

  function DiscoverSection({ title, subtitle, listItems, buttonText}) {
    return (
      <Box sx={{ backgroundColor: '#3975B1', minHeight: '90vh' }}>
        <Box
          sx={{
            ...styles.root,
            overflow: 'hidden',
            minHeight: '80vh',
            maxWidth: '100%',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <Box sx={{ ...styles.content, paddingLeft: { xs: 0, md: '5vw' }, width: { xs: '100%', md: '50%' } }}>
            <Typography variant="h1" sx={styles.header}>
              {title}
            </Typography>
            <Typography variant="body1" sx={styles.subheader}>
              {subtitle}
            </Typography>
            <List>
              {listItems.map((item, index) => (
                <ListItem key={index} sx={styles.listItem}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                      <Typography variant="body1" sx={{ color: '#FDD06B', fontWeight: '800', display: 'flex', alignItems: 'center', fontSize: '30px' }}>
                        {item.title}
                      </Typography>
                      <Typography variant="body1" sx={{ color: '#FFF0D0' }}>
                        {item.description}
                      </Typography>
                    </div>
                  </div>
                </ListItem>
              ))}
                <Button
                  color="inherit"
                  variant="outlined"
                  sx={{
                    backgroundColor: '#FEF8F1',
                    color: '123E64',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    width: {md: '50%', sm: '25%', xs: '25%' },
                    borderRadius: '8px',
                    borderWidth: '1px',
                    marginLeft: { xs: 0, md: '10px' },
                    marginTop: '20px',
                  }}
                  onClick={() => {
                    // Handle download button click if needed
                  }}
                >
                  {buttonText}
                </Button>
            </List>
          </Box>
          <img src="/workshopGraphic.png" alt="Tooli" style={{ ...styles.image}} />
        </Box>
        {/* <ThreeCardsRow /> */}
      </Box>
    );
  }

  // const ThreeCardsRow = () => {
  //   const cardData = [
  //     {
  //       iconImage: '/redStar.svg',
  //       mainImage: '/girlChair.svg',
  //       header: 'Download The App',
  //       body: 'Discover the unique benefits of FoodLoop and get the best experience possible.',
  //     },
  //     {
  //       iconImage: '/yellowSemicircle.svg',
  //       mainImage: '/deliveryDriver.svg',
  //       header: 'Become A Driver',
  //       body: 'FoodLoop drivers earn up to 30% more income than other food delivery services. No hidden fees, no corners cut. ',
  //     },
  //     {
  //       iconImage: '/blueStar.svg',
  //       mainImage: '/shakingHands.svg',
  //       header: 'Become A Partner',
  //       body: 'Partner with FoodLoop and skyrocket your profits, access more customers, and streamline your operations.',
  //     },
  //   ];
  
  //   return (
  //     <Box
  //       sx={{
  //         display: 'flex',
  //         flexDirection: { xs: 'column', lg: 'row' },
  //         justifyContent: 'space-between',
  //         padding: { xs: '0vh 5vh 10vh 5vh', md: '0vh 10vh 10vh 10vh' },
  //       }}
  //     >
  //       {cardData.map((card, index) => (
  //         <CustomCard
  //           key={index}
  //           iconImage={card.iconImage}
  //           mainImage={card.mainImage}
  //           header={card.header}
  //           body={card.body}
  //         />
  //       ))}
  //     </Box>
  //   );
  // };

  export default DiscoverSection;