import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const CustomCard2 = ({ iconImage, header, body,}) => {
  return (
    <Card style={{ borderRadius: '22px', height: '450px', width: '400px', marginBottom: '25px'}}>
      <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <img
          src={iconImage}
          alt="Icon"
          style={{ width: '60px', height: '60px', margin: '20px 0 0 20px' }}
        />
        <Typography variant="h5" component="div" mt={2} style={{ margin: '20px', fontWeight: 'bold', fontSize: '26px', textAlign: 'center', width: '80%' }}>
          {header}
        </Typography>
        <Typography variant="body2" color="text.secondary" mt={2} style={{ margin: '20px', fontSize: '20px', textAlign: 'center' }}>
          {body}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CustomCard2;