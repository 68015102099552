import React from "react";
import { ParallaxProvider} from "react-scroll-parallax";
import Navbar from "../components/Navbar";
import DollarsSection from "../components/page-sections/DollarsComponent";
import ProfitabilitySection from "../components/page-sections/ProfitabilitySection";
import EmployeeSection from "../components/page-sections/EmployeesSection";
import CompetitiveSection from "../components/page-sections/CompetitiveSection";


const cardData = [
  {
    iconImage: '/profile-image-03.png',
    header: 'Elliot Eyre',
    subheader: 'Co-Founder, CEO',
    body: 'Elliot is an entrepreneur and software developer from Northern Virginia. Passionate about community involvement and peer-to-peer commerce, Elliot used his coding and business experience to develop the Tooli app.',
  },
  {
    iconImage: '/profile-image-02.png',
    header: 'Chris Eyre',
    subheader: 'Co-Founder, CFO',
    body: 'Chris has been a mortgage broker for almost 20 years, with financial experience across multiple industries. His passion as a wood worker and homesteader gave him the inspiration for Tooli.',
  },{
    iconImage: '/profile-image-01.png',
    header: 'Jim Wallace',
    subheader: 'COO',
    body: 'With 27 years of Executive experience at Fortune 500 companies like Unisys, Salesforce, Nuance Communications, and Pegasystems, Jim is an expert in growing and scaling global organizations.',
  },{
    header: 'Jason Poffenberger',
    subheader: 'CTO',
    body: 'As a leading Mobile Applications Developer for the National Institute of Standards and Technology (NIST), Jason has designed, developed, and deployed applications to solve both industry and government issues.',
  },{
    iconImage: '/profile-image-04.png',
    header: 'Dan Kolansky',
    subheader: 'CIO',
    body: 'A web and software developer specializing in Flutter development, Dan has spent over a decade providing quality software solutions to businesses in Northern Virginia.',
  }
];


export default function AboutPage() {
  return (
    <ParallaxProvider>
      <Navbar />
      <div style={{height: '20vh', backgroundColor: "#123E64" }}/>
      <DollarsSection
        backgroundColor="#123E64"
        textColor="#FEFEFE"
        fontWeight="800"
        image="/shakingHands.png"
        title="Building Connections, One Tool at a Time"
        subtitle="At Tooli, our mission is to connect individuals, tools, and services in a seamless and mutually beneficial way. We believe in empowering professionals, promoting sustainability, and providing convenience for our valued users."
        buttonText="Get Started"
      />
      <ProfitabilitySection
        headerColor="#FEFEFE"
        backgroundColor="#123E64"
        mainLineColor="#FEFEFE"
        bodyColor="#FEFEFE"
        image="/tooliLogoName.svg"
        mainLine="Profitability First"
        headerText="Our Founders and the Tooli Story"
        subheaderText="Tooli was founded by a group of passionate individuals who recognized an opportunity to transform the tool and service rental industry. Fueled by a desire for transparency and fairness, they embarked on a journey to create a platform that benefits both listers and renters alike."
        buttonText="Learn More"
      />
      <EmployeeSection
        titleText="Innovators"
        subtitleText="Meet Our Team"
        cardData={cardData}
      />
      <CompetitiveSection
        title="Elevate Your Experience with Tooli."
        subtitle="Tooli stands as a testament to what's possible when innovation meets understanding. We've created a realm where professionals don't just succeed but thrive."
        items={[
          { src: "no-poverty-2.svg", title: "Community", description: "Tooli places a strong emphasis on building and supporting local communities. We believe in not only providing a convenient platform for tool and service rentals but also in ensuring that the benefits of our platform positively impact the community. By connecting local professionals with users, we foster a sense of community and contribute to local economic growth." },
          { src: "leaf-1.svg", title: "Sustainability", description: "Rooted in sustainability, Tooli offers a hyper-localized experience for both listers and users. By concentrating our efforts in one location, we can better understand and cater to the unique needs of the community. Our commitment to sustainability goes beyond just tool and service rentals – we actively work towards minimizing environmental impact and promoting eco-friendly practices throughout our operations." },
          { src: "bill-1.svg", title: "Affordability", description: "At Tooli, we believe in clear and straightforward pricing for users. We also prioritize earnings stability and predictability for our listers, ensuring that they are fairly compensated for their valuable tools and services. By offering competitive pricing and transparent fee structures, we aim to make quality tool and service rentals affordable for users while ensuring that listers receive their fair share, contributing to a balanced and thriving ecosystem." },
        ]}
        image="/constructionImage.png"
        buttonText="Get Started"
        learnMoreText="Learn More"
      />

      <ProfitabilitySection
        backgroundColor="white"
        mainLineColor="#123E64"
        bodyColor="#123E64"
        image="/hands-holding-tools.jpg"
        headerText="Tooli's Impact on Individuals, Communities, and the Environment"
        headerColor="#123E64"
        subheaderText="Tooli transforms the way individuals access tools and services, contributing to a more sustainable and empowered community."
        profitIncreaseText="30%"
        controlMenuText="100%"
        subText1="Joining Tooli contributes to a 30% reduction in unnecessary tool purchases, promoting sustainability and reducing waste."
        subText2="Increase in earnings for listers and savings for renters, creating a win-win situation for both parties involved."
        buttonText="Get Started"
      />
    </ParallaxProvider>
  );
}
