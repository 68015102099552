import axios from 'axios';

class ApiHandler {
  constructor() {
    this.api = axios.create({
      baseURL: process.env.REACT_APP_API,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  async getHeaders() {
    const token = window.localStorage.getItem('token');
    return {
      'Content-Type': 'application/json',
      'Authorization': token ? `Bearer ${token}` : undefined,
    };
  }

  async handleErrorCode(error) {
    if (error.response) {
      const status = error.response.status;
      const data = error.response.data;
      const message = data && data.message ? data.message : 'An unexpected error occurred.';

      switch (status) {
        case 401: // Unauthorized
          window.localStorage.removeItem('token');
          alert('Session expired. Please log in again.');
          break;
        case 403: // Forbidden
          alert('Access denied. You do not have permission to perform this action.');
          break;
        default:
          console.error(`API Error (${status}): ${message}`, data);
          alert(`API Error: ${message}`);
      }
    } else {
      console.error('API Request Error:', error.message);
      if (error.message === 'Network Error') {
        alert('Network Error: You are offline.');
      }
      else alert(error.message);
    }
  }

  async request(method, endpoint, data, successMessage) {
    try {
      const headers = await this.getHeaders();
      const response = await this.api.request({
        method,
        url: endpoint,
        data,
        headers
      });

      if (successMessage) {
        alert(`Success: ${successMessage}`);
      }

      return response.data;
    } catch (error) {
      this.handleErrorCode(error);
      throw error;
    }
  }

  get(endpoint) {
    return this.request('get', endpoint);
  }

  post(endpoint, data, successMessage) {
    return this.request('post', endpoint, data, successMessage);
  }

  put(endpoint, data, successMessage) {
    return this.request('put', endpoint, data, successMessage);
  }

  delete(endpoint, data, successMessage) {
    return this.request('delete', endpoint, data, successMessage);
  }
}


const apiHandler = new ApiHandler();
export default apiHandler;
