import React from "react";
import { Box} from "@mui/material";
import CloudAnimation from "../CloudAnimation"; // Assuming you have a CloudAnimation component


const styles = {
  backgroundImage: {
    backgroundImage: `url('/tooliBackgroundMain.png')`,
    backgroundBlendMode: 'multiply',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    minHeight: '100vh',
    color: '#F1F4F8',
  },
  overlay: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  logoName: {
    paddingBottom: '20vh',
    maxWidth: '90%',
    zIndex: 1, // Adjust the z-index to place it in front of clouds behind tooliLogoName
  },
  overlayBehind: {
    zIndex: 2, // Adjust the z-index to place it in front of clouds behind tooliOverlay but behind tooliLogoName
  },
};

function ParallaxBackground() {

  return (
    <Box>
      <Box sx={{ ...styles.backgroundImage, display: 'flex', flexDirection: 'row', position: 'relative' }}>
        {/* Clouds behind tooliLogoName */}
        <CloudAnimation style={styles.overlayBehind} />

        {/* tooliOverlay */}
        <img src='/tooliOverlay.png' alt="Tooli Overlay" style={{
          ...styles.overlay,
          backgroundBlendMode: 'multiply',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          overflow: 'hidden',
          color: 'white',
          height: '100%',
          top: '52.6vh',
          ...styles.overlayBehind, // Apply z-index
        }} />

        {/* tooliLogoName */}
        <img src='/tooliLogoName.svg' alt="Tooli Logo Name" style={{
          ...styles.overlay,
          ...styles.logoName, // Apply z-index
        }} />

        {/* Clouds in front of everything */}
        <CloudAnimation style={styles.overlay} />
      </Box>
    </Box>
  );
}

export default ParallaxBackground;
