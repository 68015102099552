import React from "react";
import Button from "@mui/material/Button";
import {
  Container,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function TransparencySection({ mainTitle, subTitle, firstContentTitle, firstContentText, secondContentTitle, secondContentText, buttonText, buttonClickHandler, learnMoreHandler }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Adjust breakpoint as needed

  return (
    <Box sx={{ backgroundColor: '#FEFEFE', display: 'flex', minHeight: '50vh' }}>
      {!isMobile && (
        <Box style={{ flex: '1', height: '100%', maxHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
          <img
            src="/GreenCity.png"
            alt="Green City"
            style={{
              flex: '1',
              width: '100%',
              minHeight: '50vh',
              objectFit: 'cover',
              paddingBottom: '10px',
            }}
            className="top-aligned-image"
          />
        </Box>
      )}
      {/* Text Content */}
      <Box sx={{ flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: { xs: '5vw', md: '2vw' }, paddingRight: { xs: '5vw', md: '30px' } }}>
        <Typography variant="h1" fontWeight="1000" color="#123E64" gutterBottom sx={{ fontSize: { xs: '20px', md: '40px', lg: '60px' }, paddingTop: '15vh' }}>
          {mainTitle}
        </Typography>
        <Typography variant="body1" paddingBottom="16px" color="#123E64" sx={{ width: { xs: '100%', md: '80%' }, paddingTop: { xs: '30px', md: '50px' }, fontSize: { xs: '12px', md: '14px', lg: '18px' } }}>
          {subTitle}
        </Typography>
        <Box sx={{ flex: '1', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', paddingRight: { xs: '5vw', md: '30px' }, paddingTop: { xs: '30px', md: '50px' }, maxHeight: '200px' }}>
          <Container sx={{ padding: '0 30px 0 0' }}>
            <Typography variant="h6" fontWeight="800" color="#123E64" gutterBottom sx={{minWidth: '200px', fontSize: { xs: '12px', md: '24px' } }}>
              {firstContentTitle}
            </Typography>
            <Typography variant="body1" color="#123E64" gutterBottom sx={{ fontSize: { xs: '14px', md: '16px' } }}>
              {firstContentText}
            </Typography>
          </Container>
          <Container sx={{ padding: '0 30px 0 0' }}>
            <Typography variant="h6" fontWeight="800" color="#123E64" gutterBottom sx={{ minWidth: '200px', fontSize: { xs: '12px', md: '24px' } }}>
              {secondContentTitle}
            </Typography>
            <Typography variant="body1" color="#123E64" gutterBottom sx={{ fontSize: { xs: '14px', md: '16px' } }}>
              {secondContentText}
            </Typography>
          </Container>
        </Box>
        <Box sx={{ flex: '1', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', paddingRight: { xs: '5vw', md: '30px' }, paddingTop: { xs: '0px', md: '10px' }, marginBottom: '20px', alignItems: 'center', maxHeight: '200px' }}>
          <Button
            color='inherit'
            variant='outlined'
            sx={{
              backgroundColor: 'white',
              color: '#123E64',
              borderColor: '#123E64',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              height: { xs: '30px', md: '50px' },
              width: '50%',
              borderRadius: '8px',
              borderWidth: '1px',
              fontSize: { xs: '12px', md: '14px', lg: '16px' },
            }}
            onClick={buttonClickHandler || (() => {
              // Default click handler
            })}
          >
            {buttonText || "Sign Up"}
          </Button>
          <Button
            variant="text"
            endIcon={<ArrowForwardIcon />}
            sx={{ color: '#123E64', margin: { xs: '0 0 0 0', md: '0 0 0 20px' }, height: '50px', fontWeight: 'bold', fontSize: { xs: '14px', md: '16px', lg: '16px'  } }}
            onClick={learnMoreHandler || (() => {
              // Default click handler
            })}
          >
            Learn More
          </Button>
        </Box>
      </Box>
      {/* Image */}
    </Box>
  );
}

  export default TransparencySection;