import React from "react";
import {
  Typography,
  Box,
} from "@mui/material";

import CustomCard2 from "../BenefitsCards";

const styles = {
  header: {
    fontWeight: '1000',
    fontSize: '80px', // Default font size for larger screens (lg)
      '@media (max-width: 1200px)': {
        fontSize: '70px', // Adjust for medium screens (md)
      },
      '@media (max-width: 992px)': {
        fontSize: '60px', // Adjust for small screens (sm)
      },
      '@media (max-width: 768px)': {
        fontSize: '40px', // Adjust for extra-small screens (xs)
      },
  },
  };

  const ProfitsSection = ({ titleText, subtitleText, cardData }) => {
    return (
      <div style={{ backgroundColor: '#FEFAF0', minHeight: '50vh', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
        <div style={{
          alignItems: 'center',
          textAlign: 'center',
        }}>
          <div>
            <Typography variant="h5" style={{ fontWeight: 'lighter', color: '#123E64', letterSpacing: 3, paddingBottom: '2vh', paddingTop: '5vh' }}>
              {titleText}
            </Typography>
            <Typography variant="h1" sx={{ ...styles.header, paddingBottom: '10vh', color: '#123E64' }}>
              {subtitleText}
            </Typography>
          </div>
        </div>
        {cardData ? <ThreeCardsRow cardData={cardData} /> : null}
      </div>
    );
  }  
  
  export default ProfitsSection;

  const ThreeCardsRow = ({cardData}) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
          justifyContent: 'space-between',
          padding: { xs: '0vh 5vh 10vh 5vh', md: '0vh 10vh 10vh 10vh' },
        }}
      >
        {cardData.map((card, index) => (
            <CustomCard2
              key={index}
              iconImage={card.iconImage}
              header={card.header}
              body={card.body}
            />
        ))}
      </Box>
    );
  };