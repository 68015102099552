import React from 'react';
import { Grid, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const GridWithCheckIcons = ({ indexedTextEntries }) => {
  return (
    <Grid container spacing={2}>
      {indexedTextEntries.map((entry, index) => (
        <Grid item key={index} xs={6}>
          <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', color: "white" }}>
            <CheckCircleIcon style = {{paddingRight: "10px", color:"#FFC501"}} />
            {entry}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default GridWithCheckIcons;


