import React from "react";
import Button from "@mui/material/Button";
import {
  Grid,
  Typography,
} from "@mui/material";

const styles = {
  header: {
    minWidth: '400px',
    color: '#123E64',
    fontSize: '55px', // Default font size for larger screens (lg)
      '@media (max-width: 1200px)': {
        fontSize: '45px', // Adjust for medium screens (md)
      },
      '@media (max-width: 992px)': {
        fontSize: '35px', // Adjust for small screens (sm)
      },
      '@media (max-width: 768px)': {
        fontSize: '25px', // Adjust for extra-small screens (xs)
      },
  },
  subheader: {
    marginTop: '16px', // Assuming theme.spacing(2) corresponds to 16px
    marginBottom: '32px', // Assuming theme.spacing(4) corresponds to 32px
    fontSize: '18px', // Default font size for larger screens (lg)
    color: '#123E64',
    lineHeight: 2, // Default line height for
    minWidth: '450px',
      '@media (max-width: 1200px)': {
        fontSize: '16px', // Adjust for medium screens (md)
      },
      '@media (max-width: 992px)': {
        fontSize: '14px', // Adjust for small screens (sm)
      },
      '@media (max-width: 768px)': {
        fontSize: '12px', // Adjust for extra-small screens (xs)
      },
  },
}

export default function DollarsSection({ image, title, subtitle, buttonText, fontWeight, backgroundColor, textColor}) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: "center", backgroundColor: backgroundColor}}>
      <Grid container spacing={3} style={{ padding: '5vw 10vw 5vw 10vw' }}>
        {/* Left Column */}
        <Grid item xs={12} md={6}>
          <Typography variant="h1" fontWeight = {fontWeight ||'900'} sx={{ ...styles.header, color: textColor}}>
            {title}
          </Typography>
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} md={6}>
          <Typography variant="body1" style={{ lineHeight: '2', color: textColor}}>
            {subtitle}
          </Typography>
          <Button variant="contained" style={{ color: backgroundColor, backgroundColor: textColor, width: '30%', marginTop: '5vh'}}>
            {buttonText}
          </Button>
        </Grid>
      </Grid>
      <img src={image} alt='money plate' style={{ width: '75vw', height: 'auto', borderRadius: '60px', marginBottom: '5vw' }} />
    </div>
  );
}