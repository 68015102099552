import React from 'react';
import { Container, Typography, Link, Box, useTheme, Divider } from '@mui/material';

const Footer = () => {
  const theme = useTheme();

  const footerStyle = {
    height: '50vh',
    background: '#FEFEFE',
    color: 'black',
    padding: '16px',
  };

  const headingStyle = {
    margin: '25px',
    fontSize: theme.typography.h4.fontSize,
  };

  const linkBoxStyle = {
    margin: '0 25px',
  };

  const linkContainerStyle = {
    marginBottom: '12px',
    display: 'block',
  };

  const linkStyle = {
    color: 'black',
  };

  return (
    <footer style={footerStyle}>
      <Divider style={{backgroundColor: '#3975B1', borderWidth: '1px', marginBottom: '10vh'}} />
      <Container>
        <Typography variant="h4" style={headingStyle}>
          <span>© 2024&nbsp;</span>Tooli LLC
        </Typography>
        <section style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start', margin: '0 -25px', padding: '25px', width: 'calc(100% + 50px)' }}>
          {/* Company Links */}
          <Box style={linkBoxStyle}>
            <div>
              <Link href="/about" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
                <Typography variant="body1">About Us</Typography>
              </Link>
              <Link href="#" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
                <Typography variant="body1">Investor Relations</Typography>
              </Link>
              <Link href="/news" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
                <Typography variant="body1">Insights and Updates</Typography>
              </Link>
              <Link href="/careers" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
                <Typography variant="body1">Career Opportunities</Typography>
              </Link>
            </div>
          </Box>
          {/* Product Links */}
          <Box style={linkBoxStyle}>
            <div>
              <Link href="/culinaryexperiences" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
                <Typography variant="body1">Rent Tools</Typography>
              </Link>
              <Link href="/driver" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
                <Typography variant="body1">List Tools</Typography>
              </Link>
              <Link href="#" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
                <Typography variant="body1">Hire Services</Typography>
              </Link>
            </div>
          </Box>
          {/* Other Links */}
          <Box style={linkBoxStyle}>
            <div>
              <Link href="/community" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
                <Typography variant="body1">Global Impact Initiatives</Typography>
              </Link>
              <Link href="/safety" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
                <Typography variant="body1">Prioritizing Safety</Typography>
              </Link>
              <Link href="/diversity" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
                <Typography variant="body1">Celebrating Diversity</Typography>
              </Link>
              <Link href="/sustainability" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
                <Typography variant="body1">Championing Sustainability</Typography>
              </Link>
              <Link href="/innovation" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
                <Typography variant="body1">Exploring New Innovations</Typography>
              </Link>
            </div>
          </Box>
        </section>
      </Container>
    </footer>
  );
};

export default Footer;



